import React from "react";
import "./style.css";

export const Mobile = ({rates}) => {
  return (
    <div className="mobile">
      <div className="div">
        <div className="overlap">
          <img className="background" alt="Background" src="/img/mobile/background.png" />
          <img className="group" alt="Group" src="/img/mobile/group-3.png" />
          <div className="content">
            <div className="overlap-group">
              <div className="chart-wrapper">
                <div className="chart">
                  <div className="div-wrapper">
                    <div className="list-column-wrapper">
                      <img className="chart-2" alt="Chart" src="/img/web/chart-2.png"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="typography">
                <div className="text-wrapper-2">Data driven decision making</div>
                <p className="p">
                  Fourleaf offers analytical tools and expertise in liquidity management, empowering token issuers and
                  institutions to make well-informed decisions.
                </p>
              </div>
              <a href="mailto:info@fourleaf.tech">
                <button className="button">
                  <div className="text-wrapper">Get in Touch</div>
                </button>
              </a>
            </div>
          </div>
          <div className="typography-wrapper">
            <div className="typography-2">
              <div className="text-wrapper-2">Data Collection and Distribution</div>
              <p className="text-wrapper-3">
                We aggregate and distribute data from centralized and decentralized exchanges.
              </p>
              <a href="mailto:info@fourleaf.tech">
                <button className="button-2">
                  <div className="text-wrapper">Get in Touch</div>
                </button>
              </a>
            </div>
          </div>
          <div className="card-assets-wrapper">
            <div className="card-assets">
              <div className="group-2">
                <div className="overlap-group-4">
                  <div className="text-wrapper-4">BTC</div>
                  <div className="text-wrapper-5">USD {rates.BTC?.rate.toFixed(2)}</div>
                  <div className="text-wrapper-6">Bitcoin</div>
                  <div className={`frameb-${7 - (rates.BTC?.percentChange < 0)}`}>
                    <img className="bold-arrow-up" alt="Bold arrow up" src={`/img/mobile/bold-arrow-up-3-${3 - (rates.BTC?.percentChange < 0)}.svg`} />
                    <div className="text-wrapper-7">{rates.BTC?.percentChange.toFixed(2)}%</div>
                  </div>
                  <div className="btc">
                    <div className="vector-wrapper">
                      <img className="vector" alt="Vector" src="/img/mobile/vector.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="graphic" alt="Graphic" src="/img/mobile/graphic.svg" />
          <div className="feature">
            <div className="group-wrapper">
              <div className="group-3">
                <div className="overlap-group-5">
                  <div className="background-2" />
                  <div className="frame-2">
                    <img className="group-4" alt="Group" src="/img/mobile/group-2.png" />
                    <div className="title-wrapper">
                      <p className="title">
                        Monitor and track
                        <br />
                        actual market
                        <br />
                        liquidity across exchanges
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-5">
            <div className="group-6">
              <div className="group-7">
                <div className="overlap-group-6">
                  <div className="background-3" />
                  <div className="frame-wrapper">
                    <div className="frame-3">
                      <img className="group-8" alt="Group" src="/img/mobile/group-11103.png" />
                      <div className="title-wrapper">
                        <p className="title">
                          Real -time price feeds <br />
                          and customized metrics
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-27">
          <img className="graphic-2" alt="Graphic" src="/img/mobile/graphic-1.png" />
          <div className="IMG">
            <div className="graphic-3" />
          </div>
        </div>
        <div className="group-9">
          <div className="frame-4">
            <div className="overlap-group-7">
              <div className="group-10">
                <a href="mailto:info@fourleaf.tech">
                  <button className="label-wrapper">
                    <div className="label">Get in Touch</div>
                  </button>
                </a>
                <p className="text-wrapper-8">Get in touch with us now</p>
              </div>
            </div>
          </div>
        </div>
        <div className="group-11">
          <div className="group-12">
            <div className="overlap-28">
              <div className="graphic-4">
                <div className="overlap-group-8">
                  <div className="ellipse" />
                  <div className="ellipse-2" />
                </div>
              </div>
              <div className="group-13">
                <div className="overlap-29">
                  <div className="chart-3">
                    <div className="overlap-30">
                      <img className="vector-2" alt="Vector" src="/img/mobile/vector-72.svg" />
                      <div className="list-column-2">
                        <div className="column-49">
                          <div className="overlap-group-3">
                            <img className="stick-28" alt="Stick" src="/img/mobile/stick-26.svg" />
                            <div className="candle-46" />
                          </div>
                        </div>
                        <div className="column-50">
                          <div className="overlap-12">
                            <img className="stick-29" alt="Stick" src="/img/mobile/stick-25.svg" />
                            <div className="candle-47" />
                          </div>
                        </div>
                        <div className="column-51">
                          <div className="overlap-31">
                            <div className="candle-48" />
                            <img className="stick-30" alt="Stick" src="/img/mobile/stick-24.svg" />
                          </div>
                        </div>
                        <div className="column-52">
                          <div className="overlap-31">
                            <img className="stick-31" alt="Stick" src="/img/mobile/stick-23.svg" />
                            <div className="candle-49" />
                          </div>
                        </div>
                        <div className="column-53">
                          <div className="overlap-31">
                            <img className="stick-31" alt="Stick" src="/img/mobile/stick-22.svg" />
                            <div className="candle-50" />
                          </div>
                        </div>
                        <div className="column-54">
                          <div className="overlap-5">
                            <img className="stick-32" alt="Stick" src="/img/mobile/stick-21.svg" />
                            <div className="candle-51" />
                          </div>
                        </div>
                        <div className="column-55">
                          <div className="overlap-32">
                            <img className="stick-33" alt="Stick" src="/img/mobile/stick-20.svg" />
                            <div className="candle-52" />
                          </div>
                        </div>
                        <div className="column-56">
                          <div className="overlap-group-2">
                            <img className="stick-34" alt="Stick" src="/img/mobile/stick-19.svg" />
                            <div className="candle-53" />
                          </div>
                        </div>
                        <div className="column-57">
                          <div className="overlap-33">
                            <img className="stick-35" alt="Stick" src="/img/mobile/stick-18.svg" />
                            <div className="candle-54" />
                          </div>
                        </div>
                        <div className="column-58">
                          <div className="overlap-31">
                            <img className="stick-31" alt="Stick" src="/img/mobile/stick-17.svg" />
                            <div className="candle-55" />
                          </div>
                        </div>
                        <div className="column-59">
                          <div className="overlap-5">
                            <img className="stick-32" alt="Stick" src="/img/mobile/stick-16.svg" />
                            <div className="candle-56" />
                          </div>
                        </div>
                        <div className="column-60">
                          <div className="overlap-5">
                            <img className="stick-36" alt="Stick" src="/img/mobile/stick-15.svg" />
                            <div className="candle-57" />
                          </div>
                        </div>
                        <div className="column-61">
                          <div className="overlap-33">
                            <img className="stick-35" alt="Stick" src="/img/mobile/stick-14.svg" />
                            <div className="candle-58" />
                          </div>
                        </div>
                        <div className="column-62">
                          <div className="overlap-33">
                            <img className="stick-35" alt="Stick" src="/img/mobile/stick-13.svg" />
                            <div className="candle-59" />
                          </div>
                        </div>
                        <div className="column-63">
                          <div className="overlap-7">
                            <img className="stick-37" alt="Stick" src="/img/mobile/stick-12.svg" />
                            <div className="candle-60" />
                          </div>
                        </div>
                        <div className="column-64">
                          <div className="overlap-20">
                            <img className="stick-38" alt="Stick" src="/img/mobile/stick-11.svg" />
                            <div className="candle-61" />
                          </div>
                        </div>
                        <div className="column-65">
                          <div className="overlap-3">
                            <img className="stick-39" alt="Stick" src="/img/mobile/stick-10.svg" />
                            <div className="candle-62" />
                          </div>
                        </div>
                        <div className="column-66">
                          <div className="overlap-34">
                            <img className="stick-40" alt="Stick" src="/img/mobile/stick-9.svg" />
                            <div className="candle-63" />
                          </div>
                        </div>
                        <div className="column-67">
                          <div className="overlap-35">
                            <img className="stick-41" alt="Stick" src="/img/mobile/stick-8.svg" />
                            <div className="candle-64" />
                          </div>
                        </div>
                        <div className="column-68">
                          <div className="overlap-7">
                            <img className="stick-42" alt="Stick" src="/img/mobile/stick-7.svg" />
                            <div className="candle-65" />
                          </div>
                        </div>
                        <div className="column-69">
                          <div className="overlap-7">
                            <img className="stick-42" alt="Stick" src="/img/mobile/stick-6.svg" />
                            <div className="candle-65" />
                          </div>
                        </div>
                        <div className="column-70">
                          <div className="overlap-7">
                            <img className="stick-42" alt="Stick" src="/img/mobile/stick-5.svg" />
                            <div className="candle-65" />
                          </div>
                        </div>
                        <div className="column-71">
                          <div className="overlap-7">
                            <img className="stick-42" alt="Stick" src="/img/mobile/stick-4.svg" />
                            <div className="candle-66" />
                          </div>
                        </div>
                        <div className="column-72">
                          <div className="overlap-35">
                            <img className="stick-43" alt="Stick" src="/img/mobile/stick-3.svg" />
                            <div className="candle-67" />
                          </div>
                        </div>
                        <div className="column-73">
                          <div className="overlap-36">
                            <img className="stick-44" alt="Stick" src="/img/mobile/stick-2.svg" />
                            <div className="candle-68" />
                          </div>
                        </div>
                        <div className="column-74">
                          <div className="overlap-37">
                            <img className="stick-45" alt="Stick" src="/img/mobile/stick-1.svg" />
                            <div className="candle-69" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-5">
          <div className="title-2">Data Monitoring For Market-making</div>
          <p className="subtitle">
            Keep track of market-making specific liquidity metrics.
            <br />
            Enable market-makers to supply optimal liquidity and swiftly adapt to changing market dynamics.
          </p>
        </div>
        <div className="overlap-38">
          <div className="frame-6">
            <img className="line" alt="Line" src="/img/mobile/line-61.svg" />
            <div className="group-14">
              <div className="overlap-group-9">
                <a href="#" className="support">Terms and Conditions</a>
                <div className="join-us-wrapper">
                  <a href="#" className="join-us">Privacy Policy</a>
                </div>
              </div>
            </div>
            <img className="group-15" alt="Group" src="/img/mobile/group-1.png" />
            <p className="copyright">Copyright © 2023 Fourleaf - All Rights Reserved</p>
          </div>
          <img className="IMG-2" alt="Img" src="/img/mobile/img.png" />
        </div>
        <div className="text">
          <div className="feature-2">
            <div className="overlap-group-12">
              <div className="icon" />
              <img className="group-23" alt="Group" src="/img/mobile/group-11109.png" />
            </div>
            <div className="frame-9">
              <p className="title-6">Gain comprehensive insights into the intricacies of market-making</p>
            </div>
          </div>
          <div className="feature-3">
            <div className="img-wrapper">
              <img className="group-24" alt="Group" src="/img/mobile/group.png" />
            </div>
            <div className="frame-10">
              <p className="title-7">Receive assistance in reviewing and negociatig otherwise complex deals</p>
            </div>
          </div>
          <div className="feature-4">
            <div className="img-wrapper">
              <img className="group-25" alt="Group" src="/img/mobile/group-11107.png" />
            </div>
            <div className="frame-11">
              <p className="title-8">Foster healthy competition for market making around your token</p>
            </div>
          </div>
          <div className="frame-12">
            <div className="title-9">Market-making Aggregation</div>
            <p className="subtitle-2">
              We demystify the market-making process and assist token issuers in making informed decisions while navigating the complexities of a token launch
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
