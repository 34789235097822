import React from "react";
import "./style.css";

export const Web = ({rates}) => {
  return (
    <div className="web">
      <div className="overlap-wrapper">
        <div className="overlap">
          <img className="background" alt="Background" src="/img/web/background.png" />
          <div className="bacckground" />
          <div className="content">
            <img className="group" alt="Group" src="/img/web/group-1.png" />
          </div>
          <img className="img" alt="Group" src="/img/web/group.png" />
          <img className="graphic" alt="Graphic" src="/img/web/graphic.svg" />
          <div className="IMG">
            <div className="div" />
          </div>
          <div className="frame-wrapper">
            <div className="frame">
              <div className="frame-2">
                <div className="frame-3">
                  <div className="frame-4">
                    <div className="div-wrapper">
                      <div className="frame-4">
                        <div className="overlap-group-wrapper">
                          <div className="overlap-group">
                            <div className="content-2">
                              <a href="mailto:info@fourleaf.tech">
                                <button className="button">
                                  <div className="text-wrapper">Get in Touch</div>
                                </button>
                              </a>
                              <div className="typography">
                                <div className="text-wrapper-2">Data driven decision making</div>
                                <p className="fourleaf-offers">
                                  <br />
                                  Fourleaf offers analytical tools and expertise in liquidity management, empowering
                                  token issuers and institutions to make well-informed decisions.
                                </p>
                              </div>
                            </div>
                            <div className="chart-wrapper">
                              <div className="chart">
                                <div className="overlap-2">
                                  <img className="chart-2" alt="Chart" src="/img/web/chart-2.png" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="LIST-CRYPTO">
                          <div className="card-assets">
                            <div className="text-wrapper-3">BTC</div>
                            <div className="text-wrapper-4">USD {rates.BTC?.rate.toFixed(2)}</div>
                            <div className="text-wrapper-5">Bitcoin</div>
                            <div className={`frame-${7 - (rates.BTC?.percentChange < 0)}`}>
                              <img className="bold-arrow-up" alt="Bold arrow up" src={`/img/web/bold-arrow-up-3-${3 - (rates.BTC?.percentChange < 0)}.svg`} />
                              <div className="text-wrapper-6">{rates.BTC?.percentChange.toFixed(2)}%</div>
                            </div>
                            <div className="img-2">
                              <div className="vector-wrapper">
                                <img className="vector" alt="Vector" src="/img/web/vector.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="card-assets-2">
                            <div className="text-wrapper-7">ETH</div>
                            <div className="text-wrapper-4">USD {rates.ETH?.rate.toFixed(2)}</div>
                            <div className="text-wrapper-5">Ethereum</div>
                            <div className={`frame-${7 - (rates.ETH?.percentChange < 0)}`}>
                              <img className="bold-arrow-up" alt="Bold arrow up" src={`/img/web/bold-arrow-up-3-${3 - (rates.ETH?.percentChange < 0)}.svg`} />
                              <div className="text-wrapper-6">{rates.ETH?.percentChange.toFixed(2)}%</div>
                            </div>
                            <img className="img-2" alt="Frame" src="/img/web/frame.svg" />
                          </div>
                          <div className="card-assets-3">
                            <div className="text-wrapper-8">SOL</div>
                            <div className="text-wrapper-9">USD {rates.SOL?.rate.toFixed(2)}</div>
                            <div className="text-wrapper-5">Solana</div>
                            <img className="img-2" alt="Frame" src="/img/web/frame-1.png" />
                            <div className={`frame-${7 - (rates.SOL?.percentChange < 0)}`}>
                              <img className="bold-arrow-up" alt="Bold arrow up" src={`/img/web/bold-arrow-up-3-${3 - (rates.SOL?.percentChange < 0)}.svg`} />
                              <div className="text-wrapper-6">{rates.SOL?.percentChange.toFixed(2)}%</div>
                            </div>
                          </div>
                          <div className="card-assets-4">
                            <div className={`frame-${7 - (rates.BNB?.percentChange < 0)}`}>
                              <img className="bold-arrow-up" alt="Bold arrow up" src={`/img/web/bold-arrow-up-3-${3 - (rates.BNB?.percentChange < 0)}.svg`} />
                              <div className="text-wrapper-6">{rates.BNB?.percentChange.toFixed(2)}%</div>
                            </div>
                            <div className="text-wrapper-10">BNB</div>
                            <div className="text-wrapper-9">USD {rates.BNB?.rate.toFixed(2)}</div>
                            <div className="text-wrapper-5">BNB</div>
                            <img className="img-2" alt="Btc" src="/img/web/btc.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-3">
                      <div className="text">
                        <a href="mailto:info@fourleaf.tech">
                          <button className="button-2">
                            <div className="text-wrapper">Get in Touch</div>
                          </button>
                        </a>
                        <div className="overlap-group-4">
                          <p className="subtitle">
                            We aggregate and distribute data from centralized and decentralized exchanges.
                          </p>
                          <div className="title">Data Collection And Distribution</div>
                        </div>
                      </div>
                      <div className="features">
                        <div className="feature">
                          <div className="group-wrapper">
                            <div className="group-2">
                              <div className="frame-9">
                                <img className="group-3" alt="Group" src="/img/web/group-2.png" />
                                <div className="title-wrapper">
                                  <p className="p">
                                    Monitor and track market
                                    <br />
                                    liquidity across exchanges
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="group-4">
                          <div className="group-5">
                            <div className="feature-wrapper">
                              <div className="feature-2">
                                <div className="frame-10">
                                  <img className="group-6" alt="Group" src="/img/web/group-11103.png" />
                                  <div className="title-wrapper">
                                    <p className="p">
                                      Real-time price feeds
                                      <br /> and customized metrics
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-7">
                    <div className="frame-11">
                      <img className="background-2" alt="Background" src="/img/web/background-2.png" />
                      <div className="CALL-TO-ACTION">
                        <div className="call-to-action">
                          <div className="text-2">
                            <p className="text-wrapper-11">Learn more about our services and how we can collaborate</p>
                            <p className="text-wrapper-12">Get in touch with us now</p>
                          </div>
                          <a href="mailto:info@fourleaf.tech">
                            <button className="label-wrapper">
                              <div className="label">Get in Touch</div>
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-8">
                  <div className="group-9">
                    <div className="content-4">
                      <div className="frame-12">
                        <div className="text-3">
                          <div className="feature-3">
                            <div className="overlap-group-5">
                              <div className="icon" />
                              <img className="group-10" alt="Group" src="/img/web/group-11109.png" />
                            </div>
                            <div className="frame-13">
                              <p className="title-2">
                                Gain comprehensive insights into the intricacies <br />
                                of market-making
                              </p>
                            </div>
                          </div>
                          <div className="feature-4">
                            <div className="img-wrapper">
                              <img className="group-11" alt="Group" src="/img/web/group-3.png" />
                            </div>
                            <div className="frame-14">
                              <p className="title-3">
                                Receive assistance in reviewing and negotiating <br />
                                otherwise complex deals.
                              </p>
                            </div>
                          </div>
                          <div className="feature-5">
                            <div className="img-wrapper">
                              <img className="group-12" alt="Group" src="/img/web/group-11107.png" />
                            </div>
                            <div className="frame-14">
                              <p className="title-3">
                                Foster healthy competition for market making around your token.
                              </p>
                            </div>
                          </div>
                          <div className="frame-15">
                            <div className="title-4">Market-making Aggregation</div>
                            <p className="subtitle-2">
                              We demystify the market-making process and assist token issuers in making informed decisions while navigating the complexities of a token launch.
                            </p>
                          </div>
                        </div>
                        <div className="bittrex-inc-logo" />
                      </div>
                    </div>
                    <div className="div-2">
                      <div className="group-13">
                        <div className="overlap-31">
                          <div className="div-2">
                            <div className="overlap-group-6">
                              <div className="ellipse" />
                              <div className="ellipse-2" />
                            </div>
                          </div>
                          <div className="group-14">
                            <div className="overlap-32">
                              <div className="chart-5">
                                <div className="overlap-33">
                                  <img className="vector-2" alt="Vector" src="/img/web/vector-72.svg" />
                                  <div className="list-column-2">
                                    <div className="column-51">
                                      <div className="overlap-group-3">
                                        <img className="stick-30" alt="Stick" src="/img/web/stick-50.svg" />
                                        <div className="candle-47" />
                                      </div>
                                    </div>
                                    <div className="column-52">
                                      <div className="overlap-21">
                                        <img className="stick-31" alt="Stick" src="/img/web/stick-51.svg" />
                                        <div className="candle-48" />
                                      </div>
                                    </div>
                                    <div className="column-53">
                                      <div className="overlap-23">
                                        <div className="candle-49" />
                                        <img className="stick-32" alt="Stick" src="/img/web/stick-52.svg" />
                                      </div>
                                    </div>
                                    <div className="column-54">
                                      <div className="overlap-34">
                                        <img className="stick-33" alt="Stick" src="/img/web/stick-53.svg" />
                                        <div className="candle-50" />
                                      </div>
                                    </div>
                                    <div className="column-55">
                                      <div className="overlap-34">
                                        <img className="stick-33" alt="Stick" src="/img/web/stick-54.svg" />
                                        <div className="candle-51" />
                                      </div>
                                    </div>
                                    <div className="column-56">
                                      <div className="overlap-6">
                                        <img className="stick-34" alt="Stick" src="/img/web/stick-55.svg" />
                                        <div className="candle-52" />
                                      </div>
                                    </div>
                                    <div className="column-57">
                                      <div className="overlap-7">
                                        <img className="stick-35" alt="Stick" src="/img/web/stick-56.svg" />
                                        <div className="candle-53" />
                                      </div>
                                    </div>
                                    <div className="column-58">
                                      <div className="overlap-22">
                                        <img className="stick-36" alt="Stick" src="/img/web/stick-57.svg" />
                                        <div className="candle-54" />
                                      </div>
                                    </div>
                                    <div className="column-59">
                                      <div className="overlap-35">
                                        <img className="stick-37" alt="Stick" src="/img/web/stick-58.svg" />
                                        <div className="candle-55" />
                                      </div>
                                    </div>
                                    <div className="column-60">
                                      <div className="overlap-34">
                                        <img className="stick-33" alt="Stick" src="/img/web/stick-59.svg" />
                                        <div className="candle-56" />
                                      </div>
                                    </div>
                                    <div className="column-61">
                                      <div className="overlap-6">
                                        <img className="stick-34" alt="Stick" src="/img/web/stick-60.svg" />
                                        <div className="candle-57" />
                                      </div>
                                    </div>
                                    <div className="column-62">
                                      <div className="overlap-6">
                                        <img className="stick-38" alt="Stick" src="/img/web/stick-61.svg" />
                                        <div className="candle-58" />
                                      </div>
                                    </div>
                                    <div className="column-63">
                                      <div className="overlap-35">
                                        <img className="stick-37" alt="Stick" src="/img/web/stick-62.svg" />
                                        <div className="candle-59" />
                                      </div>
                                    </div>
                                    <div className="column-64">
                                      <div className="overlap-35">
                                        <img className="stick-37" alt="Stick" src="/img/web/stick-63.svg" />
                                        <div className="candle-60" />
                                      </div>
                                    </div>
                                    <div className="column-65">
                                      <div className="overlap-26">
                                        <img className="stick-39" alt="Stick" src="/img/web/stick-64.svg" />
                                        <div className="candle-61" />
                                      </div>
                                    </div>
                                    <div className="column-66">
                                      <div className="overlap-36">
                                        <img className="stick-40" alt="Stick" src="/img/web/stick-65.svg" />
                                        <div className="candle-62" />
                                      </div>
                                    </div>
                                    <div className="column-67">
                                      <div className="overlap-8">
                                        <img className="stick-41" alt="Stick" src="/img/web/stick-66.svg" />
                                        <div className="candle-63" />
                                      </div>
                                    </div>
                                    <div className="column-68">
                                      <div className="overlap-37">
                                        <img className="stick-42" alt="Stick" src="/img/web/stick-67.svg" />
                                        <div className="candle-64" />
                                      </div>
                                    </div>
                                    <div className="column-69">
                                      <div className="overlap-10">
                                        <img className="stick-43" alt="Stick" src="/img/web/stick-68.svg" />
                                        <div className="candle-65" />
                                      </div>
                                    </div>
                                    <div className="column-70">
                                      <div className="overlap-26">
                                        <img className="stick-44" alt="Stick" src="/img/web/stick-69.svg" />
                                        <div className="candle-66" />
                                      </div>
                                    </div>
                                    <div className="column-71">
                                      <div className="overlap-26">
                                        <img className="stick-44" alt="Stick" src="/img/web/stick-70.svg" />
                                        <div className="candle-66" />
                                      </div>
                                    </div>
                                    <div className="column-72">
                                      <div className="overlap-26">
                                        <img className="stick-44" alt="Stick" src="/img/web/stick-71.svg" />
                                        <div className="candle-66" />
                                      </div>
                                    </div>
                                    <div className="column-73">
                                      <div className="overlap-26">
                                        <img className="stick-44" alt="Stick" src="/img/web/stick-72.svg" />
                                        <div className="candle-67" />
                                      </div>
                                    </div>
                                    <div className="column-74">
                                      <div className="overlap-10">
                                        <img className="stick-45" alt="Stick" src="/img/web/stick-73.svg" />
                                        <div className="candle-68" />
                                      </div>
                                    </div>
                                    <div className="column-75">
                                      <div className="overlap-38">
                                        <img className="stick-46" alt="Stick" src="/img/web/stick-74.svg" />
                                        <div className="candle-69" />
                                      </div>
                                    </div>
                                    <div className="column-76">
                                      <div className="overlap-39">
                                        <img className="stick-47" alt="Stick" src="/img/web/stick-75.svg" />
                                        <div className="candle-70" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-15">
                <div className="group-16">
                  <p className="data-monitoring-for">Data Monitoring For Market Making</p>
                  <p className="monitor-live-market">
                    Keep track of market-making specific liquidity metrics.
                    <br />
                    Enable market-makers to supply optimal liquidity and swiftly adapt to changing market dynamics.
                  </p>
                </div>
                <img className="group-17" alt="Group" src="/img/web/group-11123.png" />
              </div>
            </div>
          </div>
          <div className="simple-centered-dark">
            <div className="menu">
              <div className="group-18">
                <a href="#" className="support">Terms and Conditions</a>
                <a href="#" className="join-us">Privacy Policy</a>
              </div>
            </div>
            <img className="v" alt="V" src="/img/web/v-1.png" />
            <p className="copyright">Copyright © 2023 Fourleaf - All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};
