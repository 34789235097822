import React, { useEffect, useState } from "react";
import ReactDOMClient from "react-dom/client";
import { isMobile } from "react-device-detect";
import { Web } from "./screens/Web";
import { Mobile } from "./screens/Mobile";
import useWebSocket from 'react-use-websocket';

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);

const App = () => {
  const {
    lastJsonMessage,
  } = useWebSocket('wss://prisunix.cluster.fourleaf.tech/latest', {
    shouldReconnect: () => true,
  });
  const [rates, setRates] = useState({});

  useEffect(() => {
    if (lastJsonMessage !== null) {
      lastJsonMessage.result.percentChange = 100*lastJsonMessage.result.percentChange
      setRates((rates) => ({
        ...rates,
        ...{[lastJsonMessage.result.asset]: lastJsonMessage.result}
      }))
    }
  }, [setRates, lastJsonMessage])

  if (isMobile) {
    return <Mobile rates={rates} />
  } else {
    return <Web rates={rates} />
  }
}

root.render(<App />);
